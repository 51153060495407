@import url(https://fonts.googleapis.com/css2?family=Righteous&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
    transition: 670ms;
}

.svgg {
    width: 100%;
    padding-bottom: 100%;
    color: red;
}


.rightenous {
    font-family: 'Righteous', cursive;
}

.poppins {
    font-family: 'Poppins', sans-serif;
}

.navLinkLocation {
    border-bottom: '2px solid white';
}

.teamPicture:hover {
    box-shadow: 0 0 0px 10px #E57016;
}

.newsletterInput {
    border-bottom: 3px solid #54545A;
    color: #54545A;
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none;
    transition: 0.3s;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 1.2em;
}

.newsletterInput:focus {
    color: #E57016;
    border-bottom: 3px solid #E57016;
    border-top: none;
    border-left: none;
    border-right: none;
}

.newsletterInput:focus::-webkit-input-placeholder {
    color: #E57016;
}

.newsletterInput:focus:-ms-input-placeholder {
    color: #E57016;
}

.newsletterInput:focus::placeholder {
    color: #E57016;
}

.newsletterInput::-webkit-input-placeholder {
    -webkit-transition: 0.3s;
    transition: 0.3s;
    color: #54545A;
    font-weight: 400;
}

.newsletterInput:-ms-input-placeholder {
    -ms-transition: 0.3s;
    transition: 0.3s;
    color: #54545A;
    font-weight: 400;
}

.newsletterInput::placeholder {
    transition: 0.3s;
    color: #54545A;
    font-weight: 400;
}


.newsletterButton {
    border: 3px solid #E57016;
    border-radius: 30px;
    color: #E57016;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 1.2em;
    transition: 0.3s;
}
.newsletterButton:hover {
    background-color: #E57016;
    border-radius: 15px;
    color: white;
}

.contact::-webkit-input-placeholder {
    color: #ffff;
    font-size: 1em; 
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
}

.contact:-ms-input-placeholder {
    color: #ffff;
    font-size: 1em; 
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
}

.contact::placeholder {
    color: #ffff;
    font-size: 1em; 
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
}

.form-control:focus {
    border-color: #e57035;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset, 0px 0px 8px #e57035;
}

/* font-family: 'Righteous', cursive; */
/* font-family: 'Poppins', sans-serif; */
